// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "modules/mini_footer";
@import "modules/white_theme";
@import "modules/fonts_botkyrka";

$open-sans: "Open Sans", Arial, sans-serif;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
body {
    font-family: $open-sans;
}

.main-nav__link a {
    font-weight: bold;
    color: white;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    &:hover,
    &:focus {
        background-color: rgba(black, .2);
        color: white;
    }
}

.main-nav__link--active a {
    background-color: white;
    color: var(--primary);
}

.hero,
.highligted-content-banner,
.process-header__main {
    &::after {
        background-color: rgba($black, .2);
    }
}
